import React from "react";
import { Route, Switch } from "react-router-dom";

import Splash from "./Splash";
import Auth from "./Auth";
import Home from "./Home";
import Colors from "./Colors";
import ColorsControl from "./Colors/ColorsControl";

const Routes: React.FC = (props) => {
  return (
    <Switch>
      <Route path={"/auth"} component={Auth} />
      <Route path={"/splash"} component={Splash} />
      <Route path={"/colors/control"} component={ColorsControl} />
      <Route path={"/colors"} component={Colors} />
      <Route path={"/"} component={Home} />
    </Switch>
  );
};

export default Routes;
