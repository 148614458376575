import { useEffect, useState } from "react";
import { dbRef } from "../../firebase";
import { onValue } from "firebase/database";
import styles from "./Colors.module.scss";

export interface DbColors {
  color: string;
  animation: string;
  text?: string;
  duration?: number;
}

export const animations = ["flash", "glow", "glow-smooth"];

const Colors: React.FC = (props) => {
  const [colors, setColors] = useState<DbColors>();

  useEffect(() => {
    const unsubscribe = onValue(dbRef("colors"), (snap) => {
      setColors(snap.val());
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className={`${styles.Container} `}>
      <div
        className={`${styles.ColorContainer} ${
          colors?.animation ? styles[colors.animation] : ""
        }`}
        style={{
          backgroundColor: colors?.color || "#FFF",
          animationDuration: colors?.duration?.toString() + "s",
        }}
      >
        {colors?.text ? <div className={styles.Text}>{null}</div> : null}
      </div>
    </div>
  );
};

export default Colors;
