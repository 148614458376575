import { animations } from "..";
import Button from "../../../components/atoms/Button";
import { dbRef } from "../../../firebase";
import { set } from "firebase/database";

const ColorsControl: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2%",
      }}
    >
      {animations.map((animation) => {
        return (
          <Button
            onClick={() => {
              set(dbRef("colors/animation"), animation);
            }}
          >
            {animation}
          </Button>
        );
      })}

      <div>
        <input
          type="color"
          onChange={(e) => {
            set(dbRef("colors/color"), e.target.value);
          }}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <label>Duration (seconds)</label>
        <input
          type="range"
          min="0"
          max="100"
          onChange={(e) => {
            const num = Number(e.target.value);

            set(dbRef("colors/duration"), num / 100);
          }}
        />
      </div>
    </div>
  );
};

export default ColorsControl;
